import { call, put } from "redux-saga/effects";
import {
  LOYALTY_TRANSACTION_LIST_REQUEST,
  LOYALTY_TRANSACTION_SEARCH_CRITERIA_REQUEST,
  LOYALTY_TRANSACTION_SEARCH_CRITERIA_SUCCESS,
  LOYALTY_TRANSACTION_SEARCH_CRITERIA_FAILURE,
  LOYALTY_TRANSACTION_LIST_SUCCESS,
  LOYALTY_TRANSACTION_LIST_FAILURE,
} from "../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import loyaltyTransactionsService from "./loyalty-transaction-service";

const loadSearchCriteria = function* (action) {
  try {
    const filters = yield call(
      loyaltyTransactionsService.getFilterFields,
      action.brand,
      action.partner
    );
    yield put({
      type: LOYALTY_TRANSACTION_SEARCH_CRITERIA_SUCCESS,
      payload: filters,
    });
  } catch (e) {
    console.error("loyalty transaction filter", e);
    yield put({
      type: LOYALTY_TRANSACTION_SEARCH_CRITERIA_FAILURE,
    });
  }
};

const loadTransactions = function* (action) {
  try {
    let searchTerms = {
      page: ++action.searchTerms.page,
      size: action.searchTerms?.size,
      "sort-by": action.searchTerms.orderBy?.field,
      "sort-ascending": action.searchTerms.orderBy?.order == "asc",
      "date-from": action.searchTerms?.period?.from
        ? action.searchTerms?.period?.from
        : "",
      "date-to": action.searchTerms?.period?.to
        ? action.searchTerms?.period?.to
        : "",
      "level-group-name": action.searchTerms["level-group-name"],
      "transaction-type": action.searchTerms["transaction-type"],
    };
    const data = yield call(
      loyaltyTransactionsService.getTransactions,
      action.contractId,
      searchTerms,
    );
    yield put({
      type: LOYALTY_TRANSACTION_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loyalty transaction list", e);
    yield put({
      type: LOYALTY_TRANSACTION_LIST_FAILURE,
    });
  }
};

const LoyaltyTransactionsSaga = function* loyaltyTransactionsSaga() {
  yield takeLatest(
    LOYALTY_TRANSACTION_SEARCH_CRITERIA_REQUEST,
    loadSearchCriteria
  );
  yield takeLatest(LOYALTY_TRANSACTION_LIST_REQUEST, loadTransactions);
};

export default LoyaltyTransactionsSaga;
